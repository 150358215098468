import { createApp } from 'vue';
import ShopList from '@lmt-rpb/ShopList/ShopList.vue';
import { ShopCard } from '@/interfaces/shop-info';
import { mountReplace } from '../utils/vue-mount-replacer';

const shopListElements = document.querySelectorAll<HTMLElement>('.rpb__shop-list');

const initShopList = (shopList: ShopCard[]) => {
	const app = createApp(ShopList, {
		shopList,
	});
	mountReplace(app, shopListElements[0]);
};

const shopList: ShopCard[] = [];
shopListElements.forEach((element) => {
	if (element.dataset.storeRecords) {
		shopList.push(...JSON.parse(element.dataset.storeRecords));
	}
});

if (shopList.length) {
	initShopList(shopList);
	shopListElements.forEach((element) => {
		element.remove();
	});
}
