import { scrollIt } from '@utils/utils';

(function() {
	/**
	* Allow anchor class selector links.
	* */
	function checkHash() {
		var hash = window.location.hash;

		if (hash.match(/#\//)) {
			return;
		}

		var target = (hash.length <= 1) ? null : document.querySelector(hash.replace('#', '.'));
		var headerElement = document.querySelector('body > header');
		var elementOffsetTop = headerElement ? (headerElement.clientHeight + 10) : 0;

		if (hash && target) {
			scrollIt(target, 0, 'linear', elementOffsetTop);
		}
	}

	window.addEventListener('load', checkHash);
	window.addEventListener('hashchange', checkHash);
}());
