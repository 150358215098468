interface UCService {
	name: string;
	consent: { status: boolean; };
}

export default class UserCentrics {
	private readonly serviceName: string;

	_state = {
		consentGiven: false
	}

	get consentGiven() {
		return this._state.consentGiven;
	}

	set consentGiven(consent: boolean) {
		this._state.consentGiven = consent;
	}

	constructor(serviceName: string) {
		this.serviceName = serviceName;
	}

	init() {
		window.addEventListener('UC_UI_INITIALIZED', () => { this.handleConsent(); });
		window.addEventListener('UC_UI_CMP_EVENT', () => { this.handleConsent(); });
	}

	async handleConsent() {
		if (window.UC_UI) {
			const services:UCService[] = await window.UC_UI.getServicesFullInfo();
			const service = services.find((serviceObject) => serviceObject.name === this.serviceName);
			this.consentGiven = service?.consent.status || false;
		}
	}

	blockElement(element:any) {
		if (window.uc) {
			window.uc.blockElements(element);
		}
	}
}
