import { EventBus } from "@global-js/event-bus";

/**
 * This function takes the destination and dateFrom/dateTo values from the desktop search (via data-attributes)
 * and sets them on the pages where the mobileSearch has no values (only editorial pages)
 * the data-attributes are set in qu	icksearch-v2-tmpl on the <section> tag of the else from the if(is_editorial)
 */
document.addEventListener('DOMContentLoaded', function() {
	// searchContainer
	const searchContainer = document.getElementById('search-form-container');
	// data-attributes from the searchContainer (destination & date)

	if (searchContainer) {
		const destination = searchContainer.getAttribute('data-destination') !== 'null' && searchContainer.getAttribute('data-destination') ? JSON.parse(decodeURIComponent(searchContainer.getAttribute('data-destination'))).label : '-';
		const offerDurationAttrFrom = searchContainer.getAttribute('data-offer-duration') ? new Date(JSON.parse(decodeURIComponent(searchContainer.getAttribute('data-offer-duration'))).from) : '';
		const offerDurationAttrTo = searchContainer.getAttribute('data-offer-duration') ? new Date(JSON.parse(decodeURIComponent(searchContainer.getAttribute('data-offer-duration'))).to) : '';
		// mobileSearch variables
		const mobileSearch = document.getElementsByClassName('rpb_headerbild__search-button') ? document.getElementsByClassName('rpb_headerbild__search-button')[0] : '';
		const mobileSearchButtonName = mobileSearch && mobileSearch.getElementsByClassName('rpb_headerbild__search-button-name') ? mobileSearch.getElementsByClassName('rpb_headerbild__search-button-name')[0] : '';
		const mobileSearchButtonDate = mobileSearch && mobileSearch.getElementsByClassName('rpb_headerbild__search-button-date') ? mobileSearch.getElementsByClassName('rpb_headerbild__search-button-date')[0] : '';

		// Search Mask Data
		const searchMaskData = document.getElementById('search-mask-data');
		const dataDestination = searchMaskData ? searchMaskData.getAttribute('data-destination') : 'null';
		const dataOfferDuration = searchMaskData ? searchMaskData.getAttribute('data-offer-duration') : 'null';
		const searchMaskDataDestination = dataDestination !== 'null' && dataDestination !== null ? JSON.parse(decodeURIComponent(dataDestination)).label : '-';
		const searchMaskDataDateFrom = dataOfferDuration !== 'null' && dataOfferDuration !== null ? new Date(JSON.parse(decodeURIComponent(dataOfferDuration)).from) : '';
		const searchMaskDataTo = dataOfferDuration !== 'null' && dataOfferDuration !== null ? new Date(JSON.parse(decodeURIComponent(dataOfferDuration)).to) : '';
		// from Date Variables
		const offerDurationAttrFromMonth = offerDurationAttrFrom !== '' ? offerDurationAttrFrom.toLocaleString('default', { month: 'long' }) : searchMaskDataDateFrom.toLocaleString('default', { month: 'long' });
		const offerDurationAttrFromDay = offerDurationAttrFrom !== '' ? offerDurationAttrFrom.toLocaleString('default', { day: 'numeric' }) : searchMaskDataDateFrom.toLocaleString('default', { day: 'numeric' });

		// to Date Variables
		const offerDurationAttrToMonth = offerDurationAttrTo !== '' ? offerDurationAttrTo.toLocaleString('default', { month: 'long' }) : searchMaskDataTo.toLocaleString('default', { month: 'long' });
		const offerDurationAttrToDay = offerDurationAttrTo !== '' ? offerDurationAttrTo.toLocaleString('default', { day: 'numeric' }) : searchMaskDataTo.toLocaleString('default', { day: 'numeric' });

		// Date string
		const dateString = offerDurationAttrFromDay + '. ' + offerDurationAttrFromMonth + ' - ' + offerDurationAttrToDay + '. ' + offerDurationAttrToMonth;
		// check if these data-attributes exist
		if (mobileSearch
			&& ((searchContainer && offerDurationAttrFrom !== '' && offerDurationAttrTo !== '')
			|| (searchContainer && searchMaskDataDateFrom !== '' && searchMaskDataTo !== ''))) {
		// set destination and date
			mobileSearchButtonName.textContent = destination !== '-' ? destination : searchMaskDataDestination;
			mobileSearchButtonDate.textContent = dateString;
			// add clickEvent to mobileSearchButton to trigger logic of navbarSearchButton

			if (mobileSearch) {
				mobileSearch.addEventListener('click', function() {
					EventBus.$emit('offer-search:toggle');
				});
			}
		}
	}
});
