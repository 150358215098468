/** Sticky email button */
function initFloatingEmailButton(text, email, mountPoint) {
	const button = document.createElement('div');
	button.className = 'email-btn';
	button.innerHTML = `
			<div class="email-btn__inner-circle">
				<span class="email-btn__text-wrap">
					<p class="email-btn__text-icon"></p>
					<span class="email-btn__text-icon-text"></span>
				</span>
			</div>
			<span class="email-btn__mailto">
				${text}
				<a
					class="email-btn__link"
					data-ga-event="select_content"
					data-ga-content_type="FloatingButton"
					data-ga-item_id="E-Mail" 
                    title="Urlaubsanfrage"
					href="mailto:${email}?body=%0A%0A%0A%0AHinweis%3A%20Bitte%20geben%20Sie%20Ihre%20Rufnummer%20f%C3%BCr%20R%C3%BCckfragen%20an.%0A%0A"
				>
					${email}
				</a>
			</span>
		`;
	mountPoint.appendChild(button);

	const rollout = document.querySelector('.email-btn'),
		phoneIcon = document.querySelector('.email-btn__inner-circle');
	phoneIcon.addEventListener('click', function() {
		rollout.classList.toggle('email-btn--rolled-out');
		this.classList.toggle('email-btn__rotate-phone');
	});
}

/** check if within search/book pages. */
function isSearchAndBookPage(el) {
	const classes = ['page_search_landing', 'page_region_list', 'page_hotel_list', 'page_hotel', 'page_booking'];
	return classes.some((className) => el.classList.contains(className));
}

const kuo = document.querySelector('.kuo');

if (kuo && !isSearchAndBookPage(kuo)) {
	document.addEventListener(
		'DOMContentLoaded', initFloatingEmailButton(
			'Wir beraten Sie gerne!',
			'urlaubsanfrage@kuoni.at',
			document.querySelector('.rpb_main-content')
		)
	);
}
