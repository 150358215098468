import { requestCancelable } from '@/components/common/services/request';
import { ApiDataTypes } from '@/components/common/types';
import { OffersWithLinks } from '@/interfaces/hotel-list-types/hotel-list-types';

const url = '/v1/best-offer-for-area';
export async function getByGeoLocation(body: ApiDataTypes, area: any) {
	body.GeoAreaFilter = area;
	const response = await requestCancelable<OffersWithLinks>({ url, data: body }, 'hotelListOffers');
	return response;
}
