<template>
	<div class="shop-list">
		<div
			v-if="sectionsMap.length > 1"
			class="shop-list__links-container"
		>
			<ul
				ref="menu"
				class="shop-list__links"
			>
				<li
					v-for="s in sectionsMap"
					:id="s.name + '-menu-entry'"
					:key="s.name"
					:aria-current="firstVisibleSectionName === s.name"
				>
					<a
						:href="currentLocation + '#' + s.name"
						@click.prevent="scrollToAnchor($event, 100)"
					>
						{{ s.nameOriginal }}
					</a>
				</li>
			</ul>
		</div>
		<div
			v-for="s in sectionsMap"
			:id="s.name"
			:key="s.name"
			ref="sections"
			class="shop-list__state-container"
		>
			<h2> {{ s.nameOriginal }} </h2>
			<h3
				v-if="s.numberOfShopsInCity > 1"
			>
				<a
					v-if="s.link"
					:href="s.link"
				>Reisebüros in {{ s.city }}
				</a>
				<span v-else>Reisebüros in {{ s.city }}</span>
			</h3>
			<div class="shop-list__card-container">
				<ShopCard
					v-for="(item, index) in shopList.filter((shopinfo) => shopinfo.state === s.nameOriginal)"
					:key="index"
					:shop-list="item"
					class="shop-list__card"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { ShopInfo as ShopList } from '@/interfaces/shop-info';
import { scrollToAnchor } from '@/js/utils/utils';
import ShopCard from '../ShopCard/ShopCard.vue';

interface Props {
	shopList: ShopList[]
}

const props = defineProps<Props>();

const currentLocation = document.location.protocol + '//' + document.location.host + document.location.pathname;

const menu = ref<HTMLElement |null>(null);

const sections = ref<HTMLElement[] |null>(null);

const sectionsMap:{
	name: string,
	nameOriginal: string,
	city: string | undefined,
	element: HTMLElement | undefined,
	inViewport: boolean,
	numberOfShopsInCity: number,
	link: string}[] = [];

const firstVisibleSectionName = ref('Wien');

const scrollToMenuEntry = (stateEntry: string) => {
	const htmlElementEntry = document.querySelector(`#${stateEntry}-menu-entry`);
	if (menu.value && htmlElementEntry) {
		const currentMenuScroll = menu.value.scrollLeft as number;
		const menuPosition = menu.value.getBoundingClientRect().left as number;
		menu.value.scrollTo({
			top: 0,
			left: htmlElementEntry.getBoundingClientRect().left + currentMenuScroll - menuPosition,
			behavior: 'smooth'
		});
	}
};

const updateActiveMenu = () => {
	for (let i = 0; i < sectionsMap.length; i++) {
		sectionsMap[i].element = sections.value?.[i];
	}

	const observer = new IntersectionObserver((entries) => {
		entries.forEach((e) => {
			sectionsMap.forEach((o) => {
				if (e.target === o.element) {
					// eslint-disable-next-line no-param-reassign
					o.inViewport = e.isIntersecting;
				}
			});
			const firstVisibleSection = sectionsMap.find((s) => s.inViewport);
			if (firstVisibleSection) {
				firstVisibleSectionName.value = firstVisibleSection.name;
				scrollToMenuEntry(firstVisibleSection.name);
			}
		});
	}, {
		rootMargin: '-250px 0px 0px 0px',
	});

	sectionsMap.forEach((o) => {
		const section = document.querySelector('#' + o.name);
		if (section) {
			observer.observe(section);
		}
	});
};

const initMenu = () => {
	const statesSet = Array.from(props.shopList.filter((entry, index) => props.shopList.map((s) => s.state).indexOf(entry.state) === index));
	statesSet.forEach((s) => {
		const shops = props.shopList.filter((shopinfo) => shopinfo.city === s.city).length;
		sectionsMap.push({
			name: s.state_link,
			nameOriginal: s.state,
			city: s.city,
			inViewport: false,
			element: undefined,
			link: s.shop_list_link,
			numberOfShopsInCity: shops
		});
	});
};

onMounted(() => {
	updateActiveMenu();
});

onBeforeMount(() => {
	initMenu();
});

</script>

<style>
	html {
		scroll-behavior: smooth;
	}
</style>
<style lang="scss" scoped>
.shop-list {
	min-height: 1vh;
	max-width: 144rem;
	margin: auto;

	h2 {
		font-size: $font-very-large;
		font-weight: bold;
		text-align: left;
		padding: 3.4rem 0 2.4rem;
		font-family: $font-family-special;
	}

	h3 {
		font-size: $font-medium-large;
		font-weight: bold;
		text-align: left;
		padding: 0 0 1.6rem;
		font-family: $font-family-special;
	}

	li {
		position: relative;

		a {
			text-decoration: none;
		}

		a::before {
			content: '';
			position: absolute;
			display: block;
			bottom: -0.5rem;
			width: 0;
			height: 0.2rem;
			margin: 0 50%;
			border-radius: 0.5rem;
			background: $color-link;
			transition: all 0.15s ease-in-out;
			opacity: 0;
		}

		&[aria-current='true'] a::before {
			width: 100%;
			margin: 0;
			opacity: 1;
		}
	}

	&__links-container {
		position: sticky;
		top: 6rem;
		display: flex;
		justify-content: center;
		box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
		background: $color-white;
		transition: all 0.15s ease-out;
	}

	&__links {
		display: flex;
		padding: 0 1.1rem;
		padding-top: 1.6rem;
		padding-left: 0;
		gap: 1.6rem;
		list-style-type: none;
		overflow-x: auto;
		scrollbar-width: thin;
		margin-bottom: 0;

		@include customHorizontalScrollbar;

		li {
			margin-bottom: 1.6rem;
		}

		li:nth-child(1) a {
			margin-left: 1.1rem;
			position: relative;
			display: block;
		}

		li:last-of-type {
			margin-right: 0;
		}

		a {
			background: $color-white;
			border: none;
			padding: 0;
			font-size: $font-medium;
			font-weight: bold;
			font-family: $font-family-special;
			color: $color-link;
		}
	}

	&__state-container {
		margin: 0 1.5rem;
		scroll-margin: 10rem;
	}

	.page-header--hidden &__links-container {
		top: 0;
		position: sticky;
		transition: all 0.25s ease-out;
	}

	&__card-container {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;

		a:active,
		a:focus {
			text-decoration: none;
		}
	}

	@media (min-width: $breakpoint-small) {
		&__links-container {
			top: 8.8rem;
		}
	}

	@media (min-width: $breakpoint-medium) {
		h1 {
			font-size: $font-extra-large;
		}

		&__links-container {
			a {
				font-size: $font-large;
			}
		}
	}

	@media (min-width: $breakpoint-large) {
		h2 {
			margin: 0 3.6rem;
		}

		h3 {
			padding: 0 3.6rem 1.6rem;
		}

		&__links-container {
			a {
				font-size: $font-medium-large;
			}
		}

		&__card-container {
			display: flex;
			flex-direction: column;
			gap: 2.4rem;
			margin: 0 3.6rem;
		}
	}
}
</style>
