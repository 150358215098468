<template>
	<section class="climate-chart">
		<h3 class="climate-chart__headline">
			{{ climateLabel }}
		</h3>
		<div>
			<div class="climate-chart__infos">
				<div class="climate-chart__air-temperature">
					<base-icon
						class="climate-chart__thermometer"
						name="thermometer"
					/>
					<div>
						<p class="rpb_air-temperature-minmax--icon">
							<span class="">Max: {{ currentMonth.max }} </span><span class="rpb_temp-max" /> &deg;C
						</p>
						<p><span class="">Min: {{ currentMonth.min }} </span><span class="rpb_temp-min" /> &deg;C</p>
					</div>
				</div>
				<div class="climate-chart__climate-stats">
					<p class="climate-chart__label">
						<span class="climate-chart__label-text">Sonnenstunden: </span>
						<span class="climate-chart__label-value">{{ currentMonth.sunshineHours }}</span>
					</p>
					<p class="climate-chart__label">
						<span class="climate-chart__label-text">Wassertemperatur: </span>
						<span class="climate-chart__label-value">{{ currentMonth.waterTemp }} &deg;C</span>
					</p>
				</div>
			</div>
			<div class="rpb_destination__climate-graph">
				<div class="climate-chart__climate-legend">
					<p>30&deg;</p>
					<p>20&deg;</p>
					<p>10&deg;</p>
				</div>
				<Carousel
					ref="scroll"
					v-bind="carouselSettings"
					class="climate-chart__carousel carousel teaser__scroll"
					:class="['teaser__scroll--left', 'teaser__scroll--right']"
				>
					<Slide
						v-for="(month, index) in months"
						:key="index"
						:index="index"
						:style="calculateHeight(month)"
						class="climate-chart__slide"
						:class="[
							{'teaser__first-element': index == 0},
							{'teaser__last-element': index == months.length - 1},
							{'climate-chart__slide--active': currentMonth.ID === month.ID}
						]"
						@mouseenter.native="() => updateMonth(month)"
					>
						<div class="climate-chart__initial">
							{{ month.initial }}
						</div>
						<div class="climate-chart__bar " />
					</Slide>
					<template #addons>
						<CarouselNavigation>
							<template #prev>
								<span class="climate-chart__arai-label">prev</span>
								<BaseIcon
									name="chevron"
									class="climate-chart__prev-icon"
								/>
							</template>
							<template #next>
								<span class="climate-chart__arai-label">next</span>
								<BaseIcon
									name="chevron"
									class="climate-chart__next-icon"
								/>
							</template>
						</CarouselNavigation>
					</template>
				</Carousel>
			</div>
		</div>
	</section>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { ClimateData, MonthClimate } from '@/interfaces/quickinfo-types';
import { Carousel, Slide, Navigation as CarouselNavigation } from 'vue3-carousel';

interface Props {
	climateRegion: string,
	climateData: ClimateData
}
const props = defineProps<Props>();

const yAxisStart = ref(0);
const yAxisEnd = ref(40);
const scroll = ref();

const carouselSettings = {
	itemsToShow: 30,
	itemsToScroll: 1,
	snapAlign: 'start',
	breakpoints: {
		992: { // breakpoint-large
			itemsToShow: 16,
		},
		1500: { // breakpoint-large
			itemsToShow: 20,
		}
	}
};

const calculateHeight = (month: MonthClimate): string => {
	const average = (month.max + month.min) / 2;
	const num = ((average - yAxisStart.value) / (yAxisEnd.value - yAxisStart.value)) * 100;
	return `height: ${num}%`;
};

const minTemp = props.climateData?.min_temperature;

const maxTemp = props.climateData?.max_temperature;

const sunshineHours = props.climateData?.sunshine_hours;

const waterTemp = props.climateData?.water_temperature;

const months = [
	{
		initial: 'J',
		max: maxTemp[1],
		min: minTemp[1],
		sunshineHours: sunshineHours[1],
		waterTemp: waterTemp[1],
		ID: 1,
	},
	{
		initial: 'F',
		max: maxTemp[2],
		min: minTemp[2],
		sunshineHours: sunshineHours[2],
		waterTemp: waterTemp[2],
		ID: 2,
	},
	{
		initial: 'M',
		max: maxTemp[3],
		min: minTemp[3],
		sunshineHours: sunshineHours[3],
		waterTemp: waterTemp[3],
		ID: 3,
	},
	{
		initial: 'A',
		max: maxTemp[4],
		min: minTemp[4],
		sunshineHours: sunshineHours[4],
		waterTemp: waterTemp[4],
		ID: 4,
	},
	{
		initial: 'M',
		max: maxTemp[5],
		min: minTemp[5],
		sunshineHours: sunshineHours[5],
		waterTemp: waterTemp[5],
		ID: 5,
	},
	{
		initial: 'J',
		max: maxTemp[6],
		min: minTemp[6],
		sunshineHours: sunshineHours[6],
		waterTemp: waterTemp[6],
		ID: 6,
	},
	{
		initial: 'J',
		max: maxTemp[7],
		min: minTemp[7],
		sunshineHours: sunshineHours[7],
		waterTemp: waterTemp[7],
		ID: 7,
	},
	{
		initial: 'A',
		max: maxTemp[8],
		min: minTemp[8],
		sunshineHours: sunshineHours[8],
		waterTemp: waterTemp[8],
		ID: 8,
	},
	{
		initial: 'S',
		max: maxTemp[9],
		min: minTemp[9],
		sunshineHours: sunshineHours[9],
		waterTemp: waterTemp[9],
		ID: 9,
	},
	{
		initial: 'O',
		max: maxTemp[10],
		min: minTemp[10],
		sunshineHours: sunshineHours[10],
		waterTemp: waterTemp[10],
		ID: 10,
	},
	{
		initial: 'N',
		max: maxTemp[11],
		min: minTemp[11],
		sunshineHours: sunshineHours[11],
		waterTemp: waterTemp[11],
		ID: 11,
	},
	{
		initial: 'D',
		max: maxTemp[12],
		min: minTemp[12],
		sunshineHours: sunshineHours[12],
		waterTemp: waterTemp[12],
		ID: 12,
	},
];

const currentMonth = ref(months[new Date(Date.now()).getMonth()]);

const climateLabel = computed((): string => {
	const baseString = 'Wetter';

	if (!props.climateRegion) {
		return baseString;
	}

	return `${baseString} (${props.climateRegion})`;
});

onMounted(() => {
	// TODO: not working
	scroll.value.slideTo(currentMonth.value.ID);
});

const updateMonth = (month: MonthClimate): MonthClimate => {
	currentMonth.value = month;
	return currentMonth.value;
};

</script>

<style lang="scss" scoped>
.climate-chart {
	display: block;
	max-height: 37.7rem;
	padding: 0;
	min-width: 30.9rem;

	&__headline {
		text-align: left;
		font-weight: $bold-default;
		padding: 2rem 2rem 1rem;
		font-size: $font-large;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&__climate-legend {
		position: absolute;
		bottom: 1.9rem;
		font-weight: $bold-default;
		margin-left: 3.6rem;
		left: 0.5rem;
		z-index: 3;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	&__air-temperature {
		margin-right: 1rem;
		font-weight: $bold-default;
		display: flex;
		gap: 1.5rem;
		align-items: center;
	}

	&__thermometer {
		display: none;

		@media (min-width: $breakpoint-mobilelarge) {
			display: unset;
		}
	}

	&__climate-stats {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: $color-white;
	}

	&__slide {
		display: block;
		margin: 0 0.3rem;
		border-radius: 0.3rem 0.3rem 0 0;
		background: $color-primary-l4;
		position: relative;
	}

	&__slide:first-of-type {
		margin-left: 5.4rem;
	}

	&__slide--active {
		background: $color-primary;
		color: $color-white;
	}

	&__slide--active .climate-chart__bar::before {
		content: '';
		display: block;
		position: absolute;
		bottom: 16.5rem;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -10px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 20px solid $color-primary;
	}

	&__infos {
		display: flex;
		justify-content: space-between;
		align-content: center;
		height: 8rem;
		background: $color-primary;
		flex-direction: row;
		overflow: hidden;
		font-size: $font-small;
		color: $color-white;
		width: 100%;
		border-radius: 3px;
		padding: 0.5rem 1rem;

		@media (min-width: $breakpoint-mobiletabs) {
			padding: 0.5rem 1.5rem;
		}
	}

	&__initial {
		position: absolute;
		bottom: 0.5rem;
		width: 100%;
		font-weight: $bold-default;
		text-align: center;
	}

	&__bar {
		height: 100%;
	}

	&__carousel {
		:deep(.carousel__viewport) {
			display: block;
			height: 117%;
		}

		:deep(.carousel__track) {
			display: flex;
			align-items: flex-end;
			gap: 0.3rem;
			width: 250%;
			height: 18.5rem;
			border: none;
			background-image: linear-gradient(to top, $color-primary-l3 -4%, transparent 5%);
		}

		:deep(.carousel__prev),
		:deep(.carousel__next) {
			width: 3.3rem;
			height: 3.3rem;
			background: $color-white;
			border-radius: 100%;
			border: 0.1rem solid $color-primary-l4;
			z-index: 5;
		}

		:deep(.carousel__prev) {
			transform: translate3d(1.5rem, -50%, 0);
		}

		:deep(.carousel__next) {
			transform: translate3d(-1.5rem, -50%, 0);
		}

		:deep(.carousel__prev--disabled),
		:deep(.carousel__next--disabled) {
			display: none;
		}
	}

	&__label {
		display: flex;
		justify-content: space-between;
		width: 18.5rem;
	}

	&__label-text {
		width: 14.5rem;
		text-align: end;
	}

	&__label-value {
		flex-basis: 3.1rem;
		align-self: end;
		font-weight: $bold-default;
		white-space: nowrap;
	}

	&__arai-label {
		@include sr-only;
	}

	&__prev-icon,
	&__next-icon {
		fill: $color-primary;
		position: absolute;
		width: 1.7rem;
		height: 1.7rem;

		&.is-thumb {
			width: 1rem;
			height: 1rem;
		}
	}

	&__prev-icon {
		transform: rotate(-90deg);
		left: 18%;
		top: 24%;
	}

	&__next-icon {
		transform: rotate(90deg);
		right: 18%;
		top: 24%;
	}
}

.teaser__scroll {
	position: relative;

	&--left::before,
	&--right::after {
		content: '';
		position: absolute;
		z-index: 2;
		top: 1.8rem;
		bottom: -1rem;
		width: 5.3rem;
		pointer-events: none;
	}

	&--left::before {
		left: 0;
		background: linear-gradient(89deg, $color-white 0%, $color-white-t0 100%);
	}

	&--right::after {
		right: 0;
		background: linear-gradient(89deg, $color-white-t0 0%, $color-white 100%);
	}
}

</style>
