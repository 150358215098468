import { documentReady } from '../global/helper';

/**
 * animates the chevron icon
 * @param {*} target sent contained by event listener
 */
function animateIcon(target) {
	const icon = target.classList.contains('glyphicon') ? target : target.querySelector('.glyphicon');
	icon.classList.toggle('glyphicon-chevron-up');
}

// eslint-disable-next-line require-jsdoc
function toggleAccordionAnimation(event) {
	const target = event.target;
	if (window.innerWidth < 768) {
		target.closest('.rpb_store-list').querySelector('.rpb_state-stores').classList.toggle('rpb_state-stores-open');
		animateIcon(target);
	}
}

// eslint-disable-next-line require-jsdoc
function addClickEventListener() {
	// toggle store lists, only for mobile devices
	const elements = document.querySelectorAll('.rpb_store-list .rpb_state h2');
	elements.forEach((el) => el.addEventListener('click', toggleAccordionAnimation));
}

documentReady(addClickEventListener);
