import * as checkoutStorageService from '@services/localStorage/checkoutStorageService';
import { documentReady } from './global/helper';

/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
var	mouseDownObj = null;

// global lazy load options
window.lazySizesConfig = window.lazySizesConfig || {};
lazySizesConfig.lazyClass = 'rpb_lazy';
lazySizesConfig.loadingClass = 'rpb_lazy_loading';
lazySizesConfig.loadedClass = 'rpb_lazy_loaded';

// Prevent showing PWA install prompt
window.addEventListener('beforeinstallprompt', function(e) {
	e.preventDefault();
});

/** returns true if an anker tag was clicked */
function isAnkerTagNOTClicked(e) {
	return e.target.tagName !== 'A' && e.target.closest('a') === null;
}

/** returns dataset of container div and prevents default  */
function getDivDatasetFromEvent(e) {
	e.preventDefault();
	return e.target.closest('div').dataset;
}

/** fill mouseDownObj with target, so it can be checked if mouse up and down happend at same Element */
function signMouseDown(e) {
	if (isAnkerTagNOTClicked(e)) {
		e.preventDefault();
		mouseDownObj = e.target;
	}
}
/** checks if event is "signed", so its true if mouse up and down happend at same Element */
function eventIsSigned(e) {
	return mouseDownObj === e.target;
}

/** open link in new tab */
function openLinkNewTab(e) {
	const dataset = getDivDatasetFromEvent(e);
	const link = dataset.link;
	window.open(link, '_blank');
}

/** open link in new tab depending on attributes */
function openLinkInTarget(e) {
	const dataset = getDivDatasetFromEvent(e);
	const link = dataset.link;
	const linkTarget = dataset.linkTarget;
	if (linkTarget !== undefined && linkTarget !== '') {
		window.open(link, linkTarget);
	} else {
		// eslint-disable-next-line no-restricted-globals
		location.href = link;
	}
}

/** adds link functionality to data-link elements */
function addLinkFunctionality(link) {
	link.addEventListener('mousedown', signMouseDown);
	link.addEventListener('touchstart', signMouseDown);
	link.addEventListener('mouseup', (e) => {
		if (eventIsSigned(e)) {
			if (e.which === 2 && isAnkerTagNOTClicked(e)) {
				openLinkNewTab(e);
			} else if (isAnkerTagNOTClicked(e)) {
				setTimeout(openLinkInTarget(e), 0);
			}
		}
	});
	link.addEventListener('touchend', (e) => {
		if (eventIsSigned(e)) {
			setTimeout(openLinkInTarget(e), 0);
		}
	});
}
/** add link functionalty to all data link elements */
function addDataLinkEvent() {
	const dataLinks = document.querySelectorAll('[data-link]');
	dataLinks.forEach(addLinkFunctionality);
}

documentReady(addDataLinkEvent);

/**
 * global event listener for iframe resizer for TT info frames
 */
window.addEventListener('message', function(message) {
	if (message.data.event === 'csbclosed') {
		this.document.querySelector('#' + message.data.id).style.display = 'none';
	}
	if (message.data.event === 'csbresized') {
		this.document.querySelector('#' + message.data.id).style.height = message.data.height + 'px';
	}
}, false);

checkoutStorageService.clearExpired();
