// Google Maps JS Code from their docs
export default ((g) => {
	var h,
		a,
		k,
		p = 'The Google Maps JavaScript API',
		c = 'google',
		l = 'importLibrary',
		q = '__ib__',
		m = document,
		// eslint-disable-next-line vars-on-top
		b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}),
		r = new Set(),
		e = new URLSearchParams(),
		// eslint-disable-next-line no-restricted-syntax, guard-for-in, no-async-promise-executor, no-return-assign
		u = () => h || (h = new Promise(async(f, n) => {
			await (a = m.createElement('script'));
			e.set('libraries', [...r] + '');
			for (k in g)e.set(k.replace(/[A-Z]/g, (t) => '_' + t[0].toLowerCase()), g[k]); 
			e.set('callback', c + '.maps.' + q); 
			a.src = `https://maps.${c}apis.com/maps/api/js?` + e; 
			d[q] = f; a.onerror = () => h = n(Error(p + ' could not load.')); 
			a.nonce = m.querySelector('script[nonce]')?.nonce || ''; 
			m.head.append(a);
		}));
	// eslint-disable-next-line no-unused-expressions
	d[l] ? console.warn(p + ' only loads once. Ignoring:', g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n));
});
