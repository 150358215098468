import { EventBus } from '@global-js/event-bus';
import Modal from '../modal';

export class HeaderMenu {
	hamburgerbtn: Element;

	menuContainer: Element;

	constructor(hamburgerbtn: Element, menuContainer: Element) {
		this.hamburgerbtn = hamburgerbtn;
		this.menuContainer = menuContainer;
	}

	/* needs to be a lambda function, otherwise the this context wouldn't work for the listener call */
	toggleMenu = (e: Event) => {
		const clickedElement = e.target as HTMLElement;
		if (!this.menuContainer.contains(clickedElement)) {
			document.body.classList.toggle('header-main-menu-open');
			this.handleEvents(e);
		}
	}

	addClickListener() {
		this.hamburgerbtn.addEventListener('click', this.toggleMenu);
	}

	handleEvents(e: Event) {
		e.stopPropagation();
		this.closeSearchIfOpen();
		if (document.body.classList.contains('header-main-menu-open')) {
			document.body.addEventListener('click', this.toggleMenu);
		} else {
			document.body.removeEventListener('click', this.toggleMenu);
		}
	}

	closeSearchIfOpen() {
		if (document.body.classList.contains('search-open')) {
			EventBus.$emit('offer-search:toggle', false, true, true);
		}
	}
}

export function initModal() {
	const mainnavEntrys = document.querySelectorAll('.header-main-menu__entry');
	mainnavEntrys.forEach((entry) => {
		const dialog = entry?.querySelector('dialog') as HTMLDialogElement;
		const modalCloseQuery = window.matchMedia('((min-width: 768px) and (min-height: 1000px)) or (min-width: 992px)');
		const modal = new Modal(dialog, modalCloseQuery);
		entry.addEventListener('click', () => {
			if (!modalCloseQuery.matches) {
				modal.openModal();
			}
		});
		const closeElements = dialog.querySelectorAll('.page-header-dialog__header, .page-header-dialog__close-button');
		closeElements.forEach((e) => e.addEventListener('click', modal.closeModal));
	});
}
