/**
 * init Video
 */
function initVideo() {
	const videoElements = document.querySelectorAll('.rpb_video--iframe');

	if (videoElements === null) {
		return;
	}

	videoElements.forEach((v) => {
		v.addEventListener('click', (e) => {
			var videoContainer = '';
			var videoCode = '';
			var playButton = '';
			var overlay = '';
			var iframe = '';

			e.preventDefault();

			videoContainer = v.querySelector('.rpb_video__preview');
			videoCode = v.href.split('watch?v=')[1].split('&')[0];
			playButton = v.querySelector('.rpb_video__preview__playbutton');
			overlay = v.querySelector('.rpb_video__caption');
			playButton.parentNode.removeChild(playButton);
			overlay.parentNode.removeChild(overlay);
			videoContainer.style.removeProperty('background-image');
			iframe = document.createElement('iframe');
			iframe.classList.add('rpb_video__iframe');
			iframe.setAttribute('src', 'https://youtube.com/embed/' + videoCode + '?autoplay=1&mute=1&rel=0');
			iframe.setAttribute('frameborder', '0');
			iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
			iframe.setAttribute('allowfullscreen', '');
			videoContainer.appendChild(iframe);
		});
	});
}

/**
 * fireInitVideo
 */
function fireInitVideo() {
	if (window.UC_UI) {
		window.UC_UI.getServicesFullInfo().then(function(services) {
			services.forEach(function(serviceObject) {
				if (serviceObject.name === 'YouTube Video' && serviceObject.consent.status) {
					initVideo();
				}
			});
		});
	}
}

window.addEventListener('UC_UI_INITIALIZED', fireInitVideo);

window.addEventListener('UC_UI_CMP_EVENT', fireInitVideo);
