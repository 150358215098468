window.addEventListener('load', function() {
	const breadcrumbs = document.querySelector('.rpb_breadcrumb ol');
	if (breadcrumbs) {
		const container = document.querySelector('.rpb_breadcrumb');
		const classes = {
			leftOverlay: 'rpb_breadcrumb__left-overlay',
			rightOverlay: 'rpb_breadcrumb__right-overlay'
		};

		const watchScroll = () => {
			const firstListItem = breadcrumbs.firstElementChild;
			const lastListItem = breadcrumbs.lastElementChild;

			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					const overlayClass = entry.target === firstListItem ? classes.leftOverlay : classes.rightOverlay;
					if (entry.intersectionRatio !== 1) {
						if (!container.classList.contains(overlayClass)) {
							container.classList.add(overlayClass);
						}
					} else {
						container.classList.remove(overlayClass);
					}
				});
			}, { threshold: 1 });
			observer.observe(firstListItem);
			observer.observe(lastListItem);
		};

		watchScroll();
	}
});
