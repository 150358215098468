<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="shop-details">
		<div class="shop-details__wrapper">
			<h1> {{ shopInfo.name }}</h1>
			<section class="shop-details__first-section">
				<ShopContactData
					:shop-info="shopInfo"
					class="shop-details__contact-data"
				/>
				<a
					ref="mapLink"
					:href="undefined"
					target="_blank"
					class="shop-details__map-container"
					:class="{'shop-details__map-container--allowed': consentGiven}"
				><img
					:src="shopInfo.staticgmap_mobile"
					:alt="`Google Map von ${shopInfo.name}`"
					class="shop-details__map"
				/>
				</a>
			</section>
			<section
				class="shop-details__further-info"
				v-html="shopInfo.introtext_new"
			>
			</section>
			<h2> Das Team des Reisebüros {{ shopInfo.name }}</h2>
			<div class="shop-details__employees-wrapper">
				<div
					v-for="(e, index) in shopInfo.staff"
					:key="index"
					class="shop-details__employee"
				>
					<img
						v-if="e.image"
						class="shop-details__employee-img"
						width="480"
						height="554"
						:src="e.image"
						:alt="`${e.first_name} ${e.last_name}`"
					/>
					<div v-else></div>
					<div class="shop-details__employee-info">
						<span class="shop-details__employee-name">{{ e.first_name }} {{ e.last_name }}</span>
						<span class="shop-details__employee-role">{{ e.title }}</span>
						<span
							v-if="e.languages"
							class="shop-details__employee-languages"
						> Sprachen: {{ e.languages }} </span>
						<div
							v-if="e.telephone_link"
							class="shop-details__employee-phone"
						>
							<BaseIcon
								name="phone"
								class="shop-card-contact-data__phone"
								width="20"
								height="20"
							/>
							<a :href="`tel:${e.telephone_link}`"> {{ e.telephone }}</a>
						</div>
						<div
							v-if="e.email"
							class="shop-details__employee-email"
						>
							<BaseIcon
								name="envelope"
								class="shop-card-contact-data__envelope"
								width="20"
								height="20"
							/>
							<a
								:href="`mailto:${e.email}`"
								class="shop-card-contact-data__email"
							>E-Mail schreiben</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

import { ref } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { ShopDetails } from '@/interfaces/shop-info';
import ShopContactData from '../ShopContactData/ShopContactData.vue';

interface Props {
	shopInfo: ShopDetails;
}

const props = defineProps<Props>();

const mapLink = ref<HTMLAnchorElement>();

const consentGiven = ref(false);

const handleConsent = (): void => {
	if (window.UC_UI) {
		window.UC_UI.getServicesFullInfo().then((services: { name: string; consent: { status: boolean; }; }[]) => {
			services.forEach((serviceObject) => {
				if (serviceObject.name === 'Google Maps' && serviceObject.consent.status && !consentGiven.value) {
					consentGiven.value = true;
					setTimeout(() => {
						if (mapLink.value) {
							mapLink.value.href = props.shopInfo.url;
						}
					}, 200);
				}
			});
		});
	}
};

window.addEventListener('UC_UI_INITIALIZED', handleConsent);
window.addEventListener('UC_UI_CMP_EVENT', handleConsent);

</script>

<style lang="scss" scoped>
.shop-details {
	font-size: $font-very-small;
	margin: 0 0.5rem;

	&__wrapper {
		max-width: 144rem;
		margin: auto;
	}

	:deep(.shop-card-contact-data) {
		font-size: $font-very-small;
		margin-bottom: 1rem;
	}

	h1 {
		font-family: $font-family;
		font-size: $font-quite-large;
		font-weight: $bold-default;
		text-align: left;
		margin: 1rem 0 3rem;
		padding: 0;
	}

	h2 {
		font-family: $font-family;
		font-size: $font-almost-large;
		font-weight: $bold-default;
		text-align: left;
		padding: 0;
		margin: 3.2rem 0 2.8rem;
	}

	&__map-container {
		width: 31rem;
	}

	&__map-container--allowed {
		width: auto;
	}

	&__map {
		width: 100%;
	}

	&__first-section {
		margin-bottom: 2.2rem;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		row-gap: 3.2rem;
	}

	&__contact-data {
		margin: 0 0.5rem;
	}

	&__employees-wrapper {
		display: grid;
		row-gap: 4rem;
		font-size: $font-very-small;
	}

	&__employee {
		border-radius: $border-radius-small;
		box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
		padding: 3.2rem 2.4rem 2.4rem;
		text-align: center;
		margin: auto;
		width: calc(100% - 2rem);
		max-width: 28.8rem;
	}

	&__employee-info {
		display: flex;
		flex-flow: column;
	}

	&__public-transport,
	&__parking,
	&__benefits,
	&__payment-options-title {
		font-weight: $bold-default;
	}

	&__employee-img {
		border-radius: $border-radius-small;
		object-fit: cover;

		// aspect ratio 4:5
		width: 24rem;
		height: 30rem;
	}

	&__employee-name {
		font-size: $font-almost-large;
		font-weight: $bold-default;
		margin: 2.4rem 0 0.4rem;
	}

	&__employee-role {
		margin-bottom: 2.4rem;
	}

	&__employee-languages {
		margin-bottom: 2.4rem;
	}

	&__employee-phone {
		margin-bottom: 2.4rem;
		fill: $color-link;
		display: flex;
		justify-content: center;
		gap: 1.8rem;
		bottom: 1.4rem;
	}

	&__employee-email {
		fill: $color-link;
		display: flex;
		justify-content: center;
		gap: 1.8rem;
	}

	&__employee-container {
		display: flex;
		gap: 1rem;
		justify-content: space-around;
		align-items: center;
	}

	@media (min-width: $breakpoint-mobilelarge) {
		margin: 0 3.3rem;

		&__employees-wrapper {
			font-size: $font-small;
		}

		&__employee {
			height: 100%;
		}
	}

	@media (min-width: $breakpoint-small) {
		font-size: $font-small;

		h1 {
			font-size: $font-very-large;
		}

		:deep(.shop-card-contact-data) {
			font-size: $font-small;
			grid-template-rows: 4rem 4rem 4rem auto;
			grid-row-gap: 0.8rem;
		}

		&__first-section {
			flex-direction: row;
			min-height: 25rem;
		}

		&__employees-wrapper {
			grid-template-columns: repeat(2, 31rem);
			justify-content: start;
			column-gap: 3rem;
			font-size: $font-medium;
		}
	}

	@media (min-width: $breakpoint-medium) {
		font-size: $font-medium;

		:deep(.shop-card-contact-data) {
			font-size: $font-medium;
		}

		h1 {
			font-size: $font-extra-large;
		}

		h2 {
			font-size: $font-quite-large;
		}
	}

	@media (min-width: $breakpoint-large) {
		&__employees-wrapper {
			grid-template-columns: repeat(3, 31rem);
		}
	}

	@media (min-width: $breakpoint-container) {
		&__employees-wrapper {
			grid-template-columns: repeat(4, 31rem);
		}

		&__map-container {
			margin-right: 12rem;
			width: 40rem;
		}

		&__map-container--allowed {
			width: auto;
		}
	}
}
</style>
