import rbgallery from './rbgallery';

export const initRegionGallery = () => {
	/**
	 * Scan for galleries defined with data attributes.
	 */
	const elements: NodeListOf<HTMLLIElement> = document.querySelectorAll('[data-lightbox-content]');
	const videoElements: NodeListOf<HTMLLIElement> = document.querySelectorAll('[data-lightbox-video-content]');
	const mosaicData: HTMLElement | null = document.querySelector('.media-mosaic-data');
	const imagesForLightbox = mosaicData?.dataset.mosaic ? JSON.parse(mosaicData.dataset.mosaic) : null;
	mosaicData?.remove();
	const gallery = [];

	// add mosaic images & attach click listener
	for (let i = 0; i < elements.length; i++) {
		gallery.push({
			content: elements[i].dataset.lightboxContent,
			preview: elements[i].dataset.lightboxPreview || elements[i].dataset.lightboxContent,
			copyright: elements[i].dataset.lightboxCopyright || '',
			type: elements[i].dataset.lightboxType || 'image',
			dom: elements[i],
		});
	}

	// add remaining images to lightbox
	for (let i = 0; i < imagesForLightbox?.length; i++) {
		gallery.push({
			content: imagesForLightbox[i].original,
			preview: imagesForLightbox[i].small || imagesForLightbox[i].original,
			copyright: imagesForLightbox[i].copyright || '',
			type: 'image',
		});
	}

	// add videos to gallery
	for (let i = 0; i < videoElements.length; i++) {
		gallery.push({
			content: videoElements[i].dataset.lightboxVideoContent,
			preview: videoElements[i].dataset.lightboxVideoPreview || videoElements[i].dataset.lightboxVideoContent,
			copyright: videoElements[i].dataset.lightboxVideoCopyright || '',
			type: videoElements[i].dataset.lightboxVideoType || 'image',
			dom: videoElements[i],
		});
	}

	// eslint-disable-next-line new-cap
	const mainGallery = new rbgallery({}, gallery);

	const openGalleryButton = document.querySelector('.media-mosaic__button');
	if (openGalleryButton) {
		openGalleryButton.addEventListener('click', () => mainGallery.open(0));
	}
};
