import { isOfferlistPage } from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import { updateSearchAndBookUrls, getSearchParams } from '@modules/updateUrls';

const handleSearchParams = (): void => {
	if (isOfferlistPage()) {
		EventBus.$on('url:change', updateSearchAndBookUrls);
	}
	try {
		const savedSearchQueryString = sessionStorage.getItem('searchParams');
		if (savedSearchQueryString) {
			updateSearchAndBookUrls(savedSearchQueryString);
		}
	} catch (error) {
		updateSearchAndBookUrls();
	}
};

const saveSearchParams = (): void => {
	const searchParams = getSearchParams();

	if (isOfferlistPage() && searchParams) {
		try {
			sessionStorage.setItem('searchParams', searchParams);
		} catch (errorSearchParam) {
			console.log(errorSearchParam);
		}
	}

	window.removeEventListener('load', handleSearchParams);
	window.removeEventListener('beforeunload', saveSearchParams);
};

window.addEventListener('load', handleSearchParams);
window.addEventListener('beforeunload', saveSearchParams);

