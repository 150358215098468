import { EventBus } from '@global-js/event-bus';

window.addEventListener('resize', debouncedResizeHandler);

function debouncedResizeHandler(event: Event) {
	clearTimeout(window.debounceTimeoutId);

	window.debounceTimeoutId = setTimeout(function() {
		EventBus.$emit('window:resize', event);
	}, 250);
}
