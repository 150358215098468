import { addTopMarginToFirstElement } from '@utils/utils';

// Adds additional &--margin-top class to typo elements with one of the following selectors.
// (if element is under the searchform or the header image)
const selectors = [
	'.rpb_topoffers',
	'.rpb_rankinggrid',
	'.rpb_text',
	'.native-header-group',
	'.rpb_social-media-cards',
	'.travel-types-container',
	'.info-banner',
];

selectors.forEach((selector) => {
	const element = document.querySelector(selector) as HTMLElement;

	if (element) {
		addTopMarginToFirstElement(element);
	}
});
